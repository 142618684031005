<template>
<div class="match">
    <div class="select-topic">
        <div class="option-header">
            <div class="select-wrap">
                <div class="select-type select-btn" v-for="(v ,i) in selectList" @click="checkQueryType(v.id)"
                        :class="{'selected-active':checkQueryIdx == v.id }" :key="i">
                    <span v-text="v.val"></span>
                </div>
            </div>
        </div>
        <div class="option-body">
            <div class="select-date clear" @click="openPicker()" v-show="checkQueryIdx != 0">
                <div class="date left" v-text="srchDate"></div>
                <div class="drop-down-btn left"></div>
            </div>
            <div class="select-country">
                <div class="select-txt selected-country" :class="[selectType == 0 ?'whiteFont' : '']"
                        @click="setActiveBySelected(0)">自然日
                </div>
                <div class="select-txt selected-center" :class="[selectType == 1 ?'whiteFont' : '']"
                        @click="setActiveBySelected(1)">赛程日
                </div>
                <div class="check-btn" :class="'check-btn-run-'+selectType"></div>
            </div>
        </div>
        <mt-datetime-picker
                ref="picker"
                v-model="pickerVisible"
                type="date"
                year-format="{value} 年"
                month-format="{value} 月"
                @confirm="handleConfirm()"
                :endDate="getYesterday"
        ></mt-datetime-picker>
    </div>
    <section>
        <div class="titBox">
            <p>让球场次统计</p>
        </div>
        <div class="dataBox">
            <div class="dataBox_2">
                <div v-show="rqmatchList.length == 0" v-text="chartTips_2" class="chartTips"></div>
                <div v-show="rqmatchList.length > 0" id="myChart_2" :style="{width: '37.5rem', height: '25rem'}"></div>
            </div>
        </div>
    </section>
    <section>
        <div class="titBox">
            <p>每日竞猜场次</p>
            <a href="javascript:;"  @click="showLvlMask" class="tips2">赛事级别划分</a>
        </div>
        <div class="dataBox">
            <div class="dataBox_2">
                <div v-show="matchList.length == 0" v-text="chartTips_1" class="chartTips"></div>
                <div v-show="matchList.length > 0" id="myChart_1" :style="{width: '37.5rem', height: '25rem'}"></div>
            </div>
        </div>
    </section>
    <section>
        <div class="tb_data" v-show="matchList.length > 0">
            <div class="clear tb_th">
                <div class="w0">日期</div>
                <div class="w1">S级</div>
                <div class="w1">A级</div>
                <div class="w1">B级</div>
                <div class="w1">C级</div>
                <div class="w1">D级</div>
                <div class="w0">合计(场)</div>
            </div>
            <ul>
                <li class="clear tb_tr" v-for="(v, i) in matchList" :key="i" v-show="i < currShowList">
                    <div class="w0" v-text="v.rpt_date"></div>
                    <div class="w1" v-text="numFormat(v.list[0].count)"></div>
                    <div class="w1" v-text="numFormat(v.list[1].count)"></div>
                    <div class="w1" v-text="numFormat(v.list[2].count)"></div>
                    <div class="w1" v-text="numFormat(v.list[3].count)"></div>
                    <div class="w1" v-text="numFormat(v.list[4].count)"></div>
                    <div class="w0" v-text="numFormat(v.list[5].count)"></div>
                </li>
                <!-- <li class="clear tb_tr">
                    <div class="w0">合计(场)</div>
                    <div class="w1">22</div>
                    <div class="w1">45</div>
                    <div class="w1">56</div>
                    <div class="w1">13</div>
                    <div class="w1">5</div>
                    <div class="w0">545</div>
                </li> -->
            </ul>
			<div class="linkToAll" @click="showAllData" v-if="this.matchList.length >3" v-text="tips"></div>
        </div>
        <div class="foot_tips" v-show="matchList.length > 0">
            PS：以上数据不含胜负过关玩法
        </div>
    </section>
    <div class="mask" v-show="isShowMask">
        <div class="levelBox">
            <div class="nav_lvl">
                <ul class="clear">
                    <li v-for="(v, i) in lvlList" :key="i" v-text="v.league_level + '级'" @click="showLvlCont(i)" :class="lvlIdx == i ? 'on': ''"></li>
                </ul>
            </div>
            <div class="main_lvl">
                <div v-for="(item, i) in lvlCont" :key="i">
                    <p v-text="item.league_type"></p>
                    <ul class="clear">
                        <li v-for="(v, j) in item.list" :key="j" v-text="v"></li>
                    </ul>
                </div>
            </div>
            <div class="close_lvl" @click="isShowMask = 0"></div>
        </div>
    </div>
</div>
</template>

<script>

export default {
    name: 'match',
    components: {},
    data() {
        return {
            token: this.getCookie('token') || '',
            selectList: [{id: 1, val: '月统计'}, {
                id: 2,
                val: '年统计'
            }, {id: 0, val: '在售奖期统计'}],
            checkQueryIdx: 1,
            srchDate: this.formatDate(new Date(), 1),
            pickerVisible: new Date(),
            selectType: 0,
            isShowMask: 0,
            lvlList: [],
            lvlIdx: 0,
            lvlCont: [],
            matchList: [],
            rqmatchList: [],
            chartTips_1: '',
			chartTips_2: '',
			currShowList: 10,
			tips: '展开全部 >',
        }
    },
    watch: {
        'srchDate': {
            handler: function (newVal) {
                this.getMathList();
                this.getrqMatchList();
            }
        },
        'selectType': {
            handler: function (newVal) {
                this.getMathList();
                this.getrqMatchList();
            }
        },
        'checkQueryIdx': {
            handler: function (newVal) {
                if(newVal == 0) {
                    this.getMathList();
                    this.getrqMatchList();
                }
            }
        },
    },
    mounted() {
        this.getMathList()
        this.getrqMatchList();
    },
    methods: {
        checkQueryType (idx) {
            this.checkQueryIdx = idx;
            this.setShowDateByType(idx);
            this.setDateByType(idx);
        },
        setDateByType (idx) {
            this.srchDate = this.getNowFormatDate(idx)
        },
        //点击时间插件时对插件样式的操作
        setShowDateByType (idx) {
            if (idx == 1) {
                this.$refs.picker.$el.getElementsByClassName('picker-slot')[1].style.display = 'block';
                this.$refs.picker.$el.getElementsByClassName('picker-slot')[2].style.display = 'none';
            } else if (idx == 2) {
                this.$refs.picker.$el.getElementsByClassName('picker-slot')[1].style.display = 'none';
                this.$refs.picker.$el.getElementsByClassName('picker-slot')[2].style.display = 'none';
            }
        },
        openPicker () {
            this.$refs.picker.open();
            this.setShowDateByType(this.checkQueryIdx);
        },
        setActiveBySelected (type) {
            if (type != this.selectType) {
                this.selectType = type
                this.getMathList();
                this.getrqMatchList();
            }
        },
        // 格式化获取的时间
        formatDate (date, type) {
            const y = date.getFullYear()
            let m = date.getMonth() + 1
            m = m < 10 ? '0' + m : m
            let d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            if(type == 1){
                return y + '年' + m + '月'
            }else if(type == 2){
                return y + '年'
            }
        },
        //获取当前时间
        getNowFormatDate (type) {
            var date = new Date()
            var year = date.getFullYear()
            var month = date.getMonth() + 1
            var currTime = ''
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (type == 1) {
                currTime = year + '年' + month + '月'
            } else if (type == 2) {
                currTime = year + '年'
            }
            return currTime
        },
        //点击确定按钮之后
        handleConfirm () {
            // 输出格式化后的时间
            if (this.checkQueryIdx == 1) {
                this.srchDate = this.formatDate(this.$refs.picker.value,1);
            } else if (this.checkQueryIdx == 2) {
                this.srchDate = this.formatDate(this.$refs.picker.value,2);
            }
        },
        showLvlMask() {
            this.isShowMask = 1
            if(this.lvlList.length == 0) {
                this.getLeagueList()
            }
        },
        showLvlCont(idx) {
            this.lvlIdx = idx
            this.lvlCont = this.lvlList[idx].typeList
        },
        drawLine_1(mList) {
            let myChart1 = null
            let _lvlArr = []
            let _timeArr = []
            let _seriesArr = []
            let _seriesData = []
            if(mList.length > 0) {
                let _list0 = mList[0].list
                for(let i in _list0) {
                    if(_list0[i].level != '合计') {
                        _seriesArr[i] = []
                        _lvlArr.push(_list0[i].level + '级')
                    }
                }
                let mList_i = null
                for(let i in mList) {
                    if(mList[i].rpt_date != '合计') {
                        _timeArr.push(mList[i].rpt_date)
                        mList_i = mList[i].list
                        for(let j in mList_i) {
                            if(mList_i[j].level != '合计') {
                                _seriesArr[j].push(mList_i[j].count)
                            }
                        }
                    }
                }
                for(let i in _seriesArr) {
                    _seriesData.push({
                        name: _lvlArr[i],
                        type:'line',
                        stack: '总量',
                        areaStyle: {},
                        data: _seriesArr[i]
                    })
                }
                myChart1 = this.$echarts.init(document.getElementById('myChart_1'))
                myChart1.setOption({
                    tooltip : {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        }
                    },
                    legend: {
                        data: _lvlArr,
                        textStyle:{
                            fontSize: 22,
                        },
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis : [{
                        type : 'category',
                        boundaryGap : false,
                        data : _timeArr
                    }],
                    yAxis : [{type : 'value'}],
                    series : _seriesData
                })
            } else {
                _seriesData = []
                _timeArr = []
                _lvlArr = []
                myChart1 = null
            }
        },
        drawLine_2(mList) {
            let myChart2 = null;
            let _timeArr = [];
            let rqArr_0 = [];
            let rqArr_1 = [];
            let rqArr_2 = [];
            let rqArr_3 = [];
            if(mList.length > 0) {
                for(let i in mList) {
					_timeArr.push(mList[i].time);
					for(let j in mList[i].handicapList) {
						if(mList[i].handicapList[j].handicap == 0) {
							rqArr_0.push(mList[i].handicapList[j].cnt);
						} else if(mList[i].handicapList[j].handicap == 1) {
							rqArr_1.push(mList[i].handicapList[j].cnt);
						} else if(mList[i].handicapList[j].handicap == 2) {
							rqArr_2.push(mList[i].handicapList[j].cnt);
						} else if(mList[i].handicapList[j].handicap == 3) {
							rqArr_3.push(mList[i].handicapList[j].cnt);
						}
                  }
                    
                }
                myChart2 = this.$echarts.init(document.getElementById('myChart_2'))
                myChart2.setOption({
                    tooltip: {
                        trigger: 'axis',
						extraCssText: 'font-size:24px;line-height:40px;padding:20px',
						formatter: function(params) {
							let relVal = params[0].name;
							for(let i = 0; i < params.length; i ++) {
								relVal += '<br/>' +params[i].marker + params[i].seriesName + ' : ' + params[i].value + '场';
							}
							return relVal;
						}
                    },
                    legend: {
                        data: ['让0球', '让1球', '让2球', '让3球'],
                        textStyle:{
                            fontSize: 22,
                        },
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis : [{
                        type : 'category',
                        boundaryGap : false,
                        data : _timeArr
                    }],
                    yAxis : [{
						type : 'value',
					}],
                    series : [{
                        name: '让0球',
                        type: 'line',
                        data: rqArr_0
                    }, {
                        name: '让1球',
                        type: 'line',
                        data: rqArr_1
                    }, {
                        name: '让2球',
                        type: 'line',
                        data: rqArr_2
                    }, {
                        name: '让3球',
                        type: 'line',
                        data: rqArr_3
                    }]
                })
            } else {
                _timeArr = []
                myChart2 = null
            }
        },
        getMathList() {
            this.matchList = []
            this.chartTips_1 = '数据加载中...'
            this.$axios({
                method: 'post',
                url: 'web/GetMathList',
                data: {
                    token: this.token,
                    dateType: this.checkQueryIdx,
                    dateStr: this.checkQueryIdx == 0 ? null : this.getTimeParam(this.srchDate, this.checkQueryIdx),
                    timeType: this.selectType
                },
            }).then( (res) => {
                let _data = res.data
                this.chartTips_1 = ''
                if(_data.code == 0) {
                    this.matchList = _data.matchList
                    if(this.matchList.length > 0) {
                        this.drawLine_1(_data.matchList)
                    } else {
                        this.chartTips_1 = '无数据'
                    }
                } else if(_data.code == -200) {
                    this.delCookie('token')
                }
            }).catch( (err) => {
                this.hint = '网络异常'
            })
        },
        getLeagueList() {
            this.$axios({
                method: 'post',
                url: 'web/GetLeagueList',
                data: {
                    token: this.token
                },
            }).then( (res) => {
                let _data = res.data
                if(_data.code == 0) {
                    this.lvlList = _data.leagueList
                    this.lvlCont = this.lvlList[0].typeList
                } else if(_data.code == -200) {
                    this.delCookie('token')
                }
            }).catch( (err) => {
                this.hint = '网络异常'
            })
		},
		//获取让球统计数据
        getrqMatchList() {
          this.rqmatchList = []
            this.chartTips_2 = '数据加载中...'
            this.$axios({
                method: 'post',
                url: 'web/GethandicapMatchList',
                data: {
                    token: this.token,
                    dateType: this.checkQueryIdx,
                    dateStr: this.checkQueryIdx == 0 ? null : this.getTimeParam(this.srchDate, this.checkQueryIdx),
                    timeType: this.selectType
                },
            }).then( (res) => {
                let _data = res.data
                this.chartTips_2 = ''
                if(_data.code == 0) {
                    this.rqmatchList = _data.list
                    if(this.rqmatchList.length > 0) {
                        this.drawLine_2(_data.list)
                    } else {
                        this.chartTips_2 = '无数据'
                    }
                } else if(_data.code == -200) {
                    this.delCookie('token')
                }
            }).catch( (err) => {
                this.hint = '网络异常'
            })
        },
        getTimeParam(time, type) {
            if(type == 1) {
                let _year = time.substr(0, 4)
                let _month = time.substr(5, 2)
                return _year + '/' + _month
            } else if(type == 2) {
                return time.substr(0, 4)
            }
		},
		showAllData () {
			if (this.currShowList == this.matchList.length) {
				this.currShowList = 10;
				this.tips = '展开全部 >';
			} else {
				this.currShowList = this.matchList.length;
				this.tips = '收起';
			}
		},
    }
}
</script>
<style scoped>
    @import "~@/assets/css/sales.css";
    @import "~@/assets/css/match.css";
</style>
