import { render, staticRenderFns } from "./match.vue?vue&type=template&id=785a9b48&scoped=true"
import script from "./match.vue?vue&type=script&lang=js"
export * from "./match.vue?vue&type=script&lang=js"
import style0 from "./match.vue?vue&type=style&index=0&id=785a9b48&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "785a9b48",
  null
  
)

export default component.exports